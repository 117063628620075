import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';


class HeadlineElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("headline",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            <div className="mb-1">
                                                <select value={element.type} className="form-control" name="type" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                    <option value="h1"> H1 </option>
                                                    <option value="h2"> H2 </option>
                                                    <option value="h3"> H3 </option>
                                                    <option value="h4"> H4 </option>
                                                </select>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Zarovnání:</label>
                                                    </div>
                                                    <select value={element.textAlign} className="form-control" name="textAlign" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                        <option value="text-left"> vlevo </option>
                                                        <option value="text-center"> centrovaně </option>
                                                        <option value="text-right"> vpravo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Podnadpis:</label>
                                                    </div>
                                                    <select value={element.hasSubHeadline} className="form-control" name="hasSubHeadline" onChange={(e) => cm.setElementData(e,lastIndexes,"headline")}>
                                                        <option value="1"> Ano </option>
                                                        <option value="0"> Ne </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("headline",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit nadpis" title="Odstranit nadpis" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"headline",lastIndexes)}}>
                    <ContentEditable tagName={element.type} html={element.text} disabled={false} name="text" onChange={(evt) => cm.setElementData(evt,lastIndexes,"headline")} className={(element.hasSubHeadline == 1 ? element.textAlign + " has-sub-headline" : element.textAlign)} />
                    {element.hasSubHeadline == 1 &&
                        <ContentEditable tagName='p' html={element.subHeadlineText} disabled={false} name="subHeadlineText" onChange={(evt) => cm.setElementData(evt,lastIndexes,"headline")} className={element.textAlign + " sub-headline"} />
                    }
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}

            </div>
        );

    }
}

export default HeadlineElement;