/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci autentikací
 */
import Notification from '../../../Library/notification';

class Auth {

    constructor(parent){
        this.parent = parent;
    }

    changePassword(changePassword){ 

        const data = this.parent.state.password;
        let notify = new Notification();  
        
        if(data.oldPassword !== ''){
			if(data.newPassword !== '' && data.checkNewPassword !== '' && data.newPassword === data.checkNewPassword){
				
				if(data.newPassword !== data.oldPassword){
					
					changePassword({
                        variables:{
                            password:data.newPassword,
                            oldPassword:data.oldPassword
                        }
                    });
					
				}else{
					notify.setNotification(null,'Staré a nové heslo nesmí být stejné.',false,true,this.parent.props.client);
				}
				
			}else{
                notify.setNotification(null,'Nová hesla nejsou stejná.',false,true,this.parent.props.client);
			}
		}else{
            notify.setNotification(null,'Nevyplnili jste staré heslo.',false,true,this.parent.props.client);
		}
    }

    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,password:{...this.parent.state.password,[n]: v}});
              
    }

    GetObjFromRules(rules)
    {
        var obj = {};
        for(let val of rules)
        {
            obj[val.label] = true;
        }

        return obj;
    }

}

export default Auth