/**
 * soubor se seznamem veškerých graphql dotazů pro content manager
 */
import gql from 'graphql-tag';

/**
 * upload fotky na pozadí sekce
 */
export const UPLOAD_SECTION_BACKGROUND_IMAGE = gql`
    mutation UploadSectionBackgroundImage($f:Upload!){
        uploadSectionBackgroundImage(f:$f)
    }
`;