/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from 'graphql-tag';

/**
 * vložení odkazu
 */
export const ADD_EDIT_LINK = gql`
    mutation AddEditLink(
        $linkID:ID,
        $parentID: String!, 
        $active:Int, 
        $inHeader:Int, 
        $inFooter:Int, 
        $hideMenu:Int,
        $langs:[LinkLangsInput]
    ){
        addEditLink(
            linkID:$linkID,
            parentID: $parentID, 
            active:$active, 
            inHeader:$inHeader,
            inFooter:$inFooter,
            langs:$langs,
            hideMenu:$hideMenu,
        )
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_LINK_PRIORITY = gql`
    mutation updateLinkPriority($parentID:String!,$linkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateLinkPriority(parentID:$parentID,linkID:$linkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;
export const COPY_LINK = gql`
    mutation copyLink($linkID:ID) {
        copyLink(linkID:$linkID)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID!){
        linkWithLangs(linkID:$linkID){
            linkID
            active
            parentID
            inHeader
            inFooter 
            hideMenu
            langs{
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
                metaImage
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_LINKS = gql`
    query AllLinks($lang: String){
        allLinks(lang:$lang){
            linkID
            name
            active
            parentID
            inHeader
            inFooter 
            hideMenu
            subLink{
                linkID
                name
                active
                parentID
                inHeader
                inFooter 
                hideMenu
                subLink{
                    linkID
                    name
                    active
                    parentID
                    inHeader
                    inFooter 
                    hideMenu
                    subLink{
                        linkID
                        name
                        active
                        parentID
                        inHeader
                        inFooter
                        hideMenu
                        subLink{
                            linkID
                            name
                            active
                            parentID
                            inHeader
                            inFooter 
                            hideMenu
                            subLink{
                                linkID
                                name
                                active
                                parentID
                                inHeader
                                inFooter 
                                hideMenu
                                subLink{
                                    linkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter 
                                    hideMenu
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_LINKS = gql`
    mutation deleteLink($linkID:ID!,$parentID:String) {
        deleteLink(linkID:$linkID)
        reindexPriority(parentID:$parentID)
    }
`;
