import React,{ Component } from 'react';
import AllLinks from './AllLinks.js';
import AddEditLink from './AddEditLink';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showAddLink : false,
}

class Link extends Component{

    static contextType = AuthContext;

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal = this.openCloseModal.bind(this);

       this.authLib = new Auth();

    }

    

    openCloseModal(type){
        this.setState({
            showAddLink:type,
        })
    }

    render()
    {
        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showLinks)
            window.location.href="/";

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam odkazů
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                            </div>
                            <div className="card-body">
                                <AllLinks />
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAddLink &&
                    <AddEditLink openCloseModal={this.openCloseModal} />
                }		
            </div>
        );

    }

}

export default Link;