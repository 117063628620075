/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment } from 'react';
import AddEditMarketing from './AddEditMarketing';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo } from 'react-apollo';
import {GET_MARKETINGS,DELETE_MARKETINGS} from '../Queries/marketing';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import MarketingLib from '../Library/marketing';
import Helper from '../../../Library/helper';
import {MARKETING_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteMarketing:"",
    selectedMarketings:[],
    listVariables:{
        lang:"cz"
    }
}

class Marketing extends Component{

    static contextType = AuthContext;

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal  = this.openCloseModal.bind(this);
       this.deleteMarketing  = this.deleteMarketing.bind(this);
       this.delMarketing     = this.delMarketing.bind(this);
       this.showDelNotifi   = this.showDelNotifi.bind(this);

       this.authLib = new Auth();

    }

    openCloseModal(type,marketingID){
        this.setState({
            showAdd:type,
            selectedMarketingID:marketingID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteMarketings funkce, která posílá data na server
     * @param {*} marketingID ID marketingu
     */
    
    deleteMarketing(e,deleteMarketings,marketingID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedMarketings];

        if(!checked){
            for(let i in this.state.selectedMarketings){
                if(this.state.selectedMarketings[i] == marketingID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [marketingID,...arr];
        }

        
        this.setState({
            deleteMarketings,
            selectedMarketings:arr
        });
  
    }

    delMarketing(action){

        if(action){

            let marketingIDs = this.state.selectedMarketings.join(",");

            this.state.deleteMarketings({
                variables:{
                    marketingIDs: marketingIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi(){

        if(this.state.selectedMarketings.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste marketing.',false,true,this.props.client);
        }
    }

    render(){

        const {selectedMarketings,listVariables} = this.state;

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showMarketing)
            window.location.href="/";

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam marketingů
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_MARKETINGS} variables={listVariables}>
                                {
                                    ({data,loading,error }) => {
                                        
                                        if(loading) return (<Loading />);
                                        if(error) return (<Error text={error} />);

                                        return(
                                            <div>
                                                <div className="data-list">
                                                    
                                                    <div className="data-list-item header">
                                                        <div className="data-list-item-content">
                                                            <div>Obrázek</div>
                                                            <div>Nadpis</div>
                                                            <div className="text-right">Možnosti</div>
                                                        </div>
                                                    </div>
                                                    <Mutation
                                                        mutation = {DELETE_MARKETINGS}
                                                        update = {async (cache, response) => {

                                                            let marketing = new MarketingLib(this);
                                                            marketing.updateAfterDelete(cache, response,listVariables);

                                                            let notify = new Notification();
                                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                        
                                                        }}
                                                        onError = {(error) => {
                                                            const helper = new Helper(); 
                                                            let notify = new Notification();
                                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                        }}
                                                    >
                                                        {
                                                            (deleteMarketing,response) => {
                                                        
                                                                return(
                                                                    <Fragment>
                                                                        {data.allMarketings && data.allMarketings.map((item,index) => {

                                                                            var checked = false;
                                                                            for(let i in selectedMarketings){
                                                                                if(selectedMarketings[i] == item.marketingID)checked = true;
                                                                            }
                                                                                                                                            
                                                                            return (
                                                                            
                                                                                <div key={index} className="data-list-item-content">
                                                                                    <div><img src={SERVER_URL + "/" + MARKETING_IMAGE_URL + "/mala_" + item.photo} /></div>
                                                                                    <div>{item.title}</div>
                                                                                    <div className="text-right">
                                                                                        <img onClick={() => this.openCloseModal(true,item.marketingID)} className="edit-icon" src={edit} /> 
                                                                                        <input className="delete-checked" type="checkbox" name="deleteMarketing[]" checked={checked} onChange = {(e) => this.deleteMarketing(e,deleteMarketing,item.marketingID)} />
                                                                                    </div>
                                                                                </div>
                                                                                        
                                                                            )
                                                                            
                                                                        })} 
                                                                    </Fragment>
                                                                )
                                                            }
                                                        }
                                                    </Mutation>
                                                </div>
                                                {data.allMarketings && data.allMarketings.length == 0 &&
                                                    <NoItems text={"Momentálně se zde nenachází žádné marketingy."} />
                                                }
                                                
                                            </div>
                                        );
                                    }
                                }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAdd &&
                    <AddEditMarketing listVariables={listVariables} openCloseModal={this.openCloseModal} selectedMarketingID={this.state.selectedMarketingID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané marketingy ?'} callback={this.delMarketing} /> }	
            </div>
        );

    }

}

export default withApollo(Marketing);