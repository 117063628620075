/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_CAMPAIGNS,DELETE_CAMPAIGNS,COPY_CAMPAIGN} from '../Queries/campaign.js';
import AddEditCampaign from './AddEditCampaign';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import copy from '../../../Public/Images/copy_2.svg';
import Notification from '../../../Library/notification';
import CampaignLib from '../Library/campaign';
import { withApollo } from 'react-apollo';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showDoNotDelete:false,
    showAddCampaign:false,
    selectedCampaignID:0,
    campaignID:0,
    deleteCat:null,
    selectedItems:[],
    showDeleteNotifi:false

}

class Campaign extends Component{

    static contextType = AuthContext;

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.authLib = new Auth();
    }


    delCampaign = (action) => {
        if(action){
            let campaignIDs = this.state.selectedItems.join(",");
            this.state.deleteCampaigns({
                variables:{
                    campaignIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,campaignID) => {
        this.setState({
            showAddCampaign:type,
            selectedCampaignID:campaignID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteCampaigns funkce, která posílá data na server
     * @param {*} campaignID ID kategorie
     */
    
    checkItemToDelete = (e,deleteCampaigns,campaignID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == campaignID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [campaignID,...arr];
        }
        
        this.setState({
            deleteCampaigns,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCampaignModal = (type) => {
        this.setState({
            showAddCampaign:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou kampaň.',false,true,this.props.client);
        }
    }

    CopyCampaign = (copyCampaign,campaignID) =>{
        copyCampaign({
            variables:{
                campaignID
            }
        })
    }

    render(){
        
        const {selectedItems} = this.state;

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showCampaigns)
            window.location.href="/";

        return(
            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam kampaní
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_CAMPAIGNS} errorPolicy="all">
                                    {
                                        ({data,loading,error,refetch }) => {
                                            if(error){
                                                const helper = new Helper(); 
                                                return (<Error text={helper.getApolloErrorText(error)} />);
                                            }
                                            if(loading) return (<Loading />);
                                            
                                            return(
                                                <div>
                                                    <div className="data-list">
                                                        
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Název kampaně</div>
                                                                    <div className="text-center static">Celkem emailů <br/> <span style={{fontSize:"10px"}}>(vč. odhlášených)</span></div>
                                                                    <div className="text-center static">Celkem emailů <br/> <span style={{fontSize:"10px"}}>(bez odhlášených)</span></div>
                                                                    <div className="text-center static">Jedinečných <br/> <span style={{fontSize:"10px"}}>(bez odhlášených)</span></div>
                                                                    <div className="text-center static">Aktivní emaily <br/> <span style={{fontSize:"10px"}}>(bez odhlášených)</span></div>
                                                                    <div className="text-center static">Aktivní</div>
                                                                    <div className="text-right static">Možnosti</div>
                                                                </div>
                                                            </div>

                                                            <Mutation
                                                                mutation = {DELETE_CAMPAIGNS}
                                                                update = {async (cache, response) => {
                                                                    
                                                                    let campaign = new CampaignLib(this);
                                                                    campaign.updateAfterDelete(cache, response);

                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteCampaigns,response) => {
                                                                
                                                                        return(

                                                                            <Mutation
                                                                                mutation = {COPY_CAMPAIGN}
                                                                                update = {async (cache, response) => {
                                                                                    
                                                                                    refetch();
                                                                                    let notify = new Notification();   
                                                                                    notify.setNotification(null,"Věřím že upravíš můj klon ať jsem stále originál :)",true,false,this.props.client);

                                                                                }}
                                                                                onError = {(error) => {
                                                                                    const helper = new Helper(); 
                                                                                    let notify = new Notification();
                                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                                }}
                                                                            >
                                                                                {
                                                                                    (copyCampaign,response) => {
                                                                                
                                                                                        return(
                                                                            
                                                                                            <div>
                                                                                                {data.allCampaigns && data.allCampaigns.map((item,index) => {
                                                                                                    
                                                                                                    if(item.campaignID){
                                                                                                        
                                                                                                        var checked = false;
                                                                                                        for(let i in selectedItems){
                                                                                                            if(selectedItems[i] == item.campaignID)checked = true;
                                                                                                        }

                                                                                                        return (
                                                                                                            
                                                                                                            <div key={index} className="data-list-item">
                                                                                                                <div className="data-list-item-content">
                                                                                                                    <div>{item.name}</div>
                                                                                                                    <div className="static text-center">{item.contactCount}</div>
                                                                                                                    <div className="static text-center">{item.contactCountWithoutUnsubscribed}</div>
                                                                                                                    <div className="static text-center">{item.uniqueContactCount}</div>
                                                                                                                    <div className="static text-center">{item.activeContactCount}</div>
                                                                                                                    <div className="static text-center">{(item.active == 1 ? "ANO" : "NE")}</div>
                                                                                                                    <div className="static text-right">
                                                                                                                        <img onClick={() => this.openCloseModal(true,item.campaignID)} className="edit-icon" src={edit} /> 
                                                                                                                        <img title="Kopírovat kampaň" onClick={() => this.CopyCampaign(copyCampaign,item.campaignID)} className="content-icon mr-1" src={copy} /> 
                                                                                                                        <input className="delete-checked" type="checkbox" name="campaignsToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteCampaigns,item.campaignID)} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }else{
                                                                                                        return "";
                                                                                                    }
                                                                                                })}
                                                                                            </div>

                                                                                        )
                                                                                    }
                                                                                }
                                                                            </Mutation>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        
                                                    </div>
                                                    {data.allCampaigns && data.allCampaigns.length == 0 &&
                                                        <NoItems text={"Momentálně se zde nenachází žádné kampaně."} />
                                                    }
                                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané kampaně ?'} callback={this.delCampaign} /> }
                                                    {this.state.showAddCampaign &&
                                                        <AddEditCampaign openCloseModal={this.openCloseModal} selectedCampaignID={this.state.selectedCampaignID} />
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 			
            </div>
        
        );

    }

}


export default withApollo(Campaign);