import React,{ Component,Fragment } from 'react';


class AddElement extends Component{

    constructor(props){
        super(props);
    }


    render(){

        const {lastIndexes,element,cm} = this.props;

        return (
            <div className="cm-dropdown">
                <button onClick={() => cm.showAddElements(lastIndexes)}>Přidat obsah</button>
                {element.showAddElements &&
                    <Fragment>
                        <ul>
                            <li onClick={(e) => cm.addElementTo("columns",lastIndexes)}>Sloupce</li>
                            <li onClick={(e) => cm.addElementTo("headline",lastIndexes)}>Nadpis</li>
                            <li onClick={(e) => cm.addElementTo("paragraph",lastIndexes)}>Odstavec</li>
                        </ul>
                        <div className="cm-closing-overlay" onClick={() => cm.showAddElements(lastIndexes)}></div>
                    </Fragment>
                }
            </div>
        );

    }
}

export default AddElement;