/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_FORMS, GET_FORM} from '../Queries/index.js';

class Form {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addForm funkce z apollo, pomocí které se posílají data na server
     */

    addForm = (addForm) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   
             
        let langs = [];
        for(const lang of data.langs){
            langs.push({
                name: lang.name,
                text: lang.text,
                lang:lang.lang,
                sengridID:lang.sengridID,
                sengridID2:lang.sengridID2
            });
        }

        addForm({
            variables:{
                formID:data.formID,
                thanksPageTypeID:data.thanksPageTypeID,
                thanksLinkID:data.thanksLinkID,
                langs
            }
        });
        
               
    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response){

        var resp = response.data.deleteForms.split(",");

        const { allForms } = cache.readQuery({ query: GET_FORMS ,variables:{lang:'cz'}});
        var arr = [...allForms];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.formID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
        
        cache.writeQuery({ 
            query:GET_FORMS,
            variables:{lang:'cz'},
            data:{
                allForms: [...arr]
            } 
        });

    }
    
    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allForms } = cache.readQuery({ query: GET_FORMS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            cache.writeQuery({ 
                query:GET_FORMS,
                variables:variables,
                data:{
                    allForms: [response.data.addEditForm,...allForms]
                } 
            });

        }else{

            var arr = [...allForms];
            
            arr.forEach((item,index) => {
                
                if(item.formID == response.data.addEditForm.formID){
                    arr[index].formID          = response.data.addEditForm.formID;
                    arr[index].name               = response.data.addEditForm.name;
                    arr[index].text               = response.data.addEditForm.text;
                    arr[index].lang               = response.data.addEditForm.lang;
                    arr[index].sengridID          = response.data.addEditForm.sengridID;
                    arr[index].sengridID2         = response.data.addEditForm.sengridID2;
                }

            });
            cache.writeQuery({ 
                query:GET_FORMS,
                variables:variables,
                data:{
                    allForms: arr
                } 
            });
        }
    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var formID = 0;
        if(this.parent.props.selectedFormID)formID = this.parent.props.selectedFormID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_FORM,
            errorPolicy:"all",
            variables:{formID:formID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.formWithLangs){    
                let langs = this.completeLangsArray(data.data.formWithLangs.langs,data.data.allLanguageMutations);     
                this.parent.setState({
                    apolloError:"",
                    selectedFormID:formID,
                    allLinks:data.data.allLinks,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        formID:     data.data.formWithLangs.formID,
                        thanksPageTypeID:   data.data.formWithLangs.thanksPageTypeID,
                        thanksLinkID:       data.data.formWithLangs.thanksLinkID,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(formID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allLinks:data.data.allLinks,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    if(!lang.text) lang.text = "";
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    text:"",
                    sengridID:"",
                    sengridID2:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
            }
        }

              
    }
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }
    

    

}

export default Form;