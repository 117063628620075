import React,{ Fragment,useState,useContext } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NewsletterLib from '../Library/newsletter';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';
import {SEND_NEWSLETTER,GET_NEWSLETTER_DATA,DELETE_NEWSLETTERS} from '../Queries/newsletter';
import {withApollo} from 'react-apollo';
import DatePicker from 'react-datepicker';
import edit from '../../../Public/Images/edit.svg';
import sluzby from '../../../Public/Images/sluzby.jpg';
import NoItems from '../../../GlobalComponents/NoItems';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import {SERVER_URL,NEWSLETTER_IMAGE_PATH} from '../../../Config/index';
import Modal from 'react-modal';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const Newsletter = (props) => {

    const INITIAL_DATA_TO_DELETE = {
        selectedNewsletters:[],
        deleteNewsletters:null
    }

    const INITIAL_DATA_TO_NEWSLETTER = {
        newsletterID:0,
        subject:"",
        text:"",
        preheader:"",
        photo:null,
        uploadedPhoto:"",
        lang: "cz",
        testEmail:"sidris@laskyplnysvet.cz",
        isPlanned:0,
        plannedDate: new Date()
    }

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showNewsletter)
        window.location.href="/";

    let newsletterLib = new NewsletterLib(props);

    const [formData,setFormData] = useState(INITIAL_DATA_TO_NEWSLETTER);

    const [mainPhoto,setMainPhoto] = useState("");
    const [showDeleteNotifi,SetShowDeleteNotifi] = useState(false);
    const [dataToDelete,SetDataToDelete] = useState(INITIAL_DATA_TO_DELETE);
    const [showLayout, setShowLayout] = useState(false);

    const deleteNewsletter = (e,deleteNewsletters,newsletterID) => {

        var checked = e.target.checked;
        var arr = [...dataToDelete.selectedNewsletters];

        if(!checked){
            for(let i in dataToDelete.selectedNewsletters){
                if(dataToDelete.selectedNewsletters[i] == newsletterID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [newsletterID,...arr];
        }

        SetDataToDelete({
            deleteNewsletters,
            selectedNewsletters:arr
        });
  
    }

    const delNewsletter = (action) => {

        if(action){

            let newsletterIDs = dataToDelete.selectedNewsletters.join(",");

            dataToDelete.deleteNewsletters({
                variables:{
                    ids: newsletterIDs
                }
            })
        }
        SetShowDeleteNotifi(false);
        SetDataToDelete(INITIAL_DATA_TO_DELETE);
    }

    const showDelNotifi = () => {

        if(dataToDelete.selectedNewsletters.length > 0){
            SetShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste newsletter.',false,true,props.client);
        }
    }

    const editNewsletter = (item) => {

        setFormData({
            newsletterID:  item.newsletterID,
            subject:       item.subject,
            text:          item.text,
            preheader:     item.preheader,
            photo:         "",
            uploadedPhoto: item.photo,
            lang:          formData.lang,
            testEmail:     item.testEmail,
            isPlanned:     1,
            plannedDate:   new Date(item.plannedDate)
        });

        if(item.photo != ""){
            setMainPhoto(SERVER_URL + "/" + NEWSLETTER_IMAGE_PATH + "/stredni_" + item.photo);
        }else{
            setMainPhoto("");
        }

    }

    const chooseLayout = (layoutID) => {

        setFormData({...formData,text:newsletterLib.getLayoutContent(layoutID)});
        setShowLayout(false);

    }

    return(
        <div id="settings" className="whole-container" >
            	
            <div className="row" >
                <div className="col-sm one">

                    <Query query={GET_NEWSLETTER_DATA}>
                    {
                        ({data,loading,error,refetch}) => {

                            return(

                                <Mutation 
                                    mutation={SEND_NEWSLETTER}
                                    onCompleted = {(response) => {

                                        setFormData({...formData,newsletterID:response.sendOneNewsletter});
                                        
                                        let notify      = new Notification();
                                        notify.setNotification(null,"Úspěšně odesláno",true,false,props.client);

                                        refetch();
                                        
                                    }}

                                    onError = {(err) => {
                                        
                                        const helper    = new Helper(); 
                                        err             = helper.getApolloErrorText(err);
                                        let notify      = new Notification();
                                        notify.setNotification(null,err,false,true,props.client);
                                        
                                    }}
                                    
                                >
                                {
                                    (sendNewsletter,{dataAdd,loadingAdd,errorAdd}) => {

                                        return (   
                                            <div className="d-flex"> 
                                                <div className="card main" style={{maxWidth:620}}>

                                                    <div className="card-header d-flex align-items-center">
                                                        <div className="mr-3">Počty emailů:</div> 
                                                        {data && data.emailCountInNewsletter && data.emailCountInNewsletter.map((item,index) => {

                                                            return(
                                                                <div className="mr-3"><strong>{item.lang}</strong>: {item.count}</div>
                                                            )

                                                        })}

                                                    </div>
                                                    
                                                    <div className="card-header d-flex align-items-center">
                                                        Newsletter
                                                        <button className="btn btn-primary ml-auto mr-3" onClick={() => setShowLayout(true)}>Šablona</button>
                                                        <button className="btn btn-primary mr-3" onClick={() => {setFormData(INITIAL_DATA_TO_NEWSLETTER); setMainPhoto("");}}>Nový</button>
                                                        <div>
                                                            <select value={formData.lang} className="form-control" name="lang" onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)}>
                                                                {data && data.allLanguageMutations && data.allLanguageMutations.map((item,index) => (
                                                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        {error ?
                                                            <Error text={error} />
                                                        :
                                                        <>
                                                            {loading || loadingAdd ?
                                                                <Loading  />
                                                            :
                                                            <>
                                                                <div className="form-group">
                                                                    <label className="input-label">Naplánovat odeslání</label>
                                                                    <div className="form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="isPlanned" id="isPlanned1" value="1" checked={(formData.isPlanned == 1 ?  true : false)} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                            <label className="form-check-label" htmlFor="isPlanned1">
                                                                                Ano
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="isPlanned" id="isPlanned2" value="0" checked={(formData.isPlanned == 0 ?  true : false)} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                            <label className="form-check-label" htmlFor="isPlanned2">
                                                                                Ne
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div> 
                                                                {formData.isPlanned == 1 ?
                                                                    <div className="form-group">
                                                                        <label className="input-label">Datum zveřejnění</label>
                                                                        <div className="form-group">
                                                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.plannedDate} onChange={date => setFormData({...formData,plannedDate: date})} />
                                                                        </div>
                                                                    </div> 
                                                                :null}
                                                                <div className="form-group">
                                                                    <label className="input-label">Předmět emailu</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="subject" value={formData.subject} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label className="input-label">Preheader</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="preheader" value={formData.preheader} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label className="input-label">Hlavní fotka</label>
                                                                    <div className="form-group input-group">
                                                                        <div className="custom-file">
                                                                            
                                                                            <input
                                                                                type="file"
                                                                                className="custom-file-input"
                                                                                id="customFile"
                                                                                name="file"
                                                                                required
                                                                                multiple
                                                                                onChange={(e) => newsletterLib.openImage(e,formData,setFormData,setMainPhoto)}
                                                                            />
                                                                            <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {mainPhoto ?
                                                                    <div className="form-group">
                                                                        <img className = "maxwidth-100" src={mainPhoto} />
                                                                    </div>
                                                                : null }

                                                                <div className="form-group">
                                                                    <TinyMCEEditor onChange={(content,type) => newsletterLib.formHandleEditor(content,formData,setFormData)} initialValue={formData.text} />           
                                                                </div>

                                                                <div className="row">

                                                                    <div className="col-12 col-sm-6">
                                                                        <button style={{height:105}} onClick={() => newsletterLib.sendEmail(sendNewsletter,formData)} className="btn btn-block btn-primary">{formData.isPlanned == 1 ? "Uložit" : "Odeslat všem"}</button>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="testEmail" value={formData.testEmail} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} /> 
                                                                        </div>
                                                                        <button className="btn btn-block btn-primary" onClick={() => newsletterLib.sendEmail(sendNewsletter,formData,true)} >Odeslat testovací</button>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                        </>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="card main ml-3" style={{flex:1}}>
                                        
                                                    <div className="card-header d-flex align-items-center">
                                                        Seznam plánovaných newsletterů
                                                        <button className="btn btn-danger btn-thiner ml-auto" onClick={() => showDelNotifi()}>Smazat označené</button>
                                                    </div>
                                                    <div className="card-body">
                                                        {loading ?
                                                            <Loading />
                                                        
                                                        :
                                                    
                                                            <div>
                                                                <div className="data-list">
                                                                    
                                                                    <div className="data-list-item header">
                                                                        <div className="data-list-item-content">
                                                                            <div>Předmět</div>
                                                                            <div className="static text-center">Datum odeslání</div>
                                                                            <div className="static text-center">Jazyk</div>
                                                                            <div className="static text-right">Možnosti</div>
                                                                        </div>
                                                                    </div>
                                                                    <Mutation
                                                                        mutation = {DELETE_NEWSLETTERS}
                                                                        update = {async (cache, response) => {

                                                                            newsletterLib.updateAfterDelete(cache, response);

                                                                            let notify = new Notification();
                                                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                        
                                                                        }}
                                                                        onError = {(error) => {
                                                                            const helper = new Helper(); 
                                                                            let notify = new Notification();
                                                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,props.client);
                                                                        }}
                                                                    >
                                                                        {
                                                                            (deleteNewsletters,response) => {
                                                                        
                                                                                return(
                                                                                    <Fragment>
                                                                                        {data.allPlannedNewsletters && data.allPlannedNewsletters.map((item,index) => {

                                                                                            var checked = false;
                                                                                            for(let i in dataToDelete.selectedNewsletters){
                                                                                                if(dataToDelete.selectedNewsletters[i] == item.newsletterID)checked = true;
                                                                                            }
                                                                                                                                                            
                                                                                            return (
                                                                                            
                                                                                                <div key={index} className="data-list-item-content">
                                                                                                    <div>{item.subject}</div>
                                                                                                    <div className="text-center static">{item.plannedDateString}</div>
                                                                                                    <div className="text-center static">{item.lang}</div>
                                                                                                    <div className="static text-right">
                                                                                                        <img onClick={() => editNewsletter(item)} className="edit-icon" src={edit} /> 
                                                                                                        <input className="delete-checked" type="checkbox" name="deleteNewsletter[]" checked={checked} onChange = {(e) => deleteNewsletter(e,deleteNewsletters,item.newsletterID)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                        
                                                                                            )
                                                                                            
                                                                                        })} 
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                        }
                                                                    </Mutation>
                                                                </div>

                                                                {data.allPlannedNewsletters && data.allPlannedNewsletters.length == 0 ?
                                                                    <NoItems text={"Momentálně se zde nenachází žádné plánované newslettery."} />
                                                                :null}
                                                            
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Mutation>
                            )
                        }}
                    </Query>
                </div>
            </div>
            {showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané newslettery ?'} callback={delNewsletter} /> }

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={showLayout}
                onRequestClose={() => setShowLayout(false)}
            >
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h4 className="modal-title">Šablony</h4>
                        
                        <button type="button" className="close ml-3" onClick={() => setShowLayout(false)}>                                    
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="newsletter-layouts">

                            <div className="layout" onClick={() => chooseLayout(1)}>

                                <img src={sluzby} />
                                <div>Služby</div>

                            </div>
                        </div>
    
                    </div>
                </div>
            </Modal>

        </div>
    )

}

export default withApollo(Newsletter);