import React,{ Component,Fragment } from 'react';
import {Mutation, withApollo } from 'react-apollo';
import { createPortal } from 'react-dom'
import Modal from 'react-modal';
import HeadlineElement from './HeadlineElement';
import ColumnsElement from './ColumnsElement';
import ParagraphElement from './ParagraphElement';
import AddElement from './AddElement';
import plusGreen from '../../../Public/Images/plus_green.svg';
import remove from '../../../Public/Images/remove.svg';
import ContentManagerLib from '../Library/contentManager';
import {UPLOAD_SECTION_BACKGROUND_IMAGE} from '../Queries/contentManager';

const INITIAL_STATE = {
    showHelpers:true,
    content:{
        sections:[{
            backgroundColorClass:"",
            backgroundImage:"",
            fullWidth:0,
            fullHeight:0,
            paddingClass:"padding",
            showAddElements:false,
            elements:[]
        }]
    },
    contentDev:{
        sections:[{
            backgroundColor:"",
            backgroundImage:"",
            fullWidth:true,
            padding:"",
            elements:[{
                headline:{
                    type:"h1",
                    text:"Nadpis sekce",
                    textAlign:"text-center",
                    subHeadlineText:"Nějaký delší text pod hlavním nasdpisem"
                },
                columns:{
                    cols:[{
                        showAddElements:false,
                        elements:[{
                            headline:{
                                type:"h2",
                                text:"Nadpis sekce h2",
                                textAlign:"text-center",
                                hasSubHeadline:true,
                                subHeadlineText:"Nějaký delší text pod hlavním nasdpisem h2"
                            },
                        }]
                    }]

                },
                text:"<p>Zde si <strong>můžete</strong> vytvořít veškerý text Vašeho webu</p>"
            }]
        }]
    }
}

class ContentManager extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;
        
    }

    componentDidMount(){

        const {client} = this.props;
        this.cm = new ContentManagerLib(this,client);
    }

    setRef = node => {
        this.iframeNode = node.node;
    }



    render(){

        const {content,showHelpers} = this.state;
        
        return(
            <Modal
                className="Modal__Bootstrap modal-dialog modal-max"
                closeTimeoutMS={150}
                isOpen={true}
                onRequestClose={() => this.props.openContentManager(0)}
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Content manager</h4>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={showHelpers} id="defaultCheck1" onChange={() => this.cm.showHideHelpers()} />
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                ovládací prvky
                            </label>
                        </div>
                        <button className="btn btn-primary btn-thiner mr-3" onClick={() => this.cm.saveContent()}>Uložit obsah</button>
                        <button type="button" className="close" onClick={() => this.props.openContentManager(0)}>
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <Frame ref={this.setRef}>
                            {content.sections && content.sections.map((item,index) => {
                                
                                return(
                                    <section key={index} className={item.backgroundColorClass + " " + item.paddingClass + " " + (content.sections.length == 1 && item.elements.length == 0 ? "cm-min-height" : "")+ " " + (item.fullHeight == 1 ? "full-height" : "")} style={(item.backgroundImage ? {backgroundImage:"url('"+item.backgroundImage+"')"} : {})} >
                                        {content.sections.length == 1 && item.elements.length == 0 &&
                                            <div className="cm-start-info">Pro vložení prvního obsahu klikněte v pravo dole na tlačítko "přidat obsah"</div>
                                        }
                                        {showHelpers &&
                                            <div className="cm-menu">
                                                <div className="form-inline">
                                                    <div className="form-group mr-1">
                                                        <AddElement lastIndexes = {""+index+""} element={item} cm = {new ContentManagerLib(this)}  />
                                                    </div>
                                                    <div className="form-group mr-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text" htmlFor="inputGroupSelect02">Odsazení:</label>
                                                            </div>
                                                            <select value={item.paddingClass} className="form-control" name="section-padding" onChange={(e) => this.cm.setSectionPaddingClass(e,index)}>
                                                                <option value=""> Ne </option>
                                                                <option value="padding"> Ano </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mr-1">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="customFile" onChange={(e) => this.cm.uploadSectionBackgroundImage(e,index)}  />
                                                            <label className="custom-file-label" htmlFor="customFile">Obrázek pozadí</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mr-1">
                                                        <select value={item.backgroundColorClass} className="form-control" name="section-bg-color" onChange={(e) => this.cm.setSectionBackgroundColorClass(e,index)}>
                                                            <option value=""> --- barva pozadí ---</option>
                                                            <option value="grey"> šedá </option>
                                                            <option value="blue"> modrá </option>
                                                        </select>
                                                    </div>
                                                    
                                                    <div className="form-group mr-1">
                                                        <select value={item.fullWidth} className="form-control" name="section-full-width" onChange={(e) => this.cm.setSectionFullWidth(e,index)}>
                                                            <option value="0"> pevná šířka </option>
                                                            <option value="1"> na celou šířku </option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-1">
                                                        <select value={item.fullHeight} className="form-control" name="section-full-height" onChange={(e) => this.cm.setSectionFullHeight(e,index)}>
                                                            <option value="0"> auto výška </option>
                                                            <option value="1"> na celou výšku </option>
                                                        </select>
                                                    </div>
                                                    {index != 0 &&
                                                        <div className="form-group">
                                                            <div className="item-container" onClick={() => this.cm.removeSection(index)}>
                                                                <img className="remove-icon" src={remove} alt="Odstranit sekci" title="Odstranit sekci" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className={(item.fullWidth == 1 ? "container-fluid"  : "container")}>
                                            
                                            {item.elements && item.elements.map((elmItem,elmIndex) => {
                                                
                                                if(elmItem.headline){
                                                    return(<HeadlineElement key = {elmIndex} element = {elmItem.headline} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this)} showHelpers = {showHelpers} />)
                                                }
                                                if(elmItem.columns){
                                                    return(<ColumnsElement fullWidth={item.fullWidth} iframeNode = {this.iframeNode} key = {elmIndex} element = {elmItem.columns} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this)} showHelpers = {showHelpers} />)
                                                }
                                                if(elmItem.paragraph){
                                                    return(<ParagraphElement iframeNode = {this.iframeNode} key = {elmIndex} element = {elmItem.paragraph} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this)} showHelpers = {showHelpers} />)
                                                }
                                                
                                            })}  

                                        </div>

                                        {showHelpers && <img src={plusGreen} className="cm-add-section" onClick={() => this.cm.addSection(index)} alt="Přidat sekci" title="Přidat sekci" />}
                                        {showHelpers && <hr className="cm-hr" />}

                                    </section>
                            )})}
                               
                        </Frame>
                        

                    </div>
                </div>

            </Modal>
        )

    }
}

class Head extends Component {

    render(){
        return(
            <Fragment>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/bootstrap.css"}></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManager.css"}></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/web.css"}></link>
            </Fragment>
        )
    }
}

class Frame extends Component {
    constructor(props) {
      super(props)

      this.state = {
        contentRef:"",
        headRef:""
      }
    }

    componentDidMount() {
        this.node.addEventListener("load", this.handleLoad);
    }

    componentWillUnmout() {
        this.node.removeEventListener("load", this.handleLoad);
    }

    handleLoad = () => {
        this.iframeBody = this.node.contentDocument.body;
        this.iframeHead = this.node.contentDocument.head;
        this.forceUpdate();
    };

  
    render() {
      const { children, ...props } = this.props // eslint-disable-line
      
      return (

        <iframe srcDoc={`<!DOCTYPE html>`} ref={node => (this.node = node)}>
            {this.iframeBody &&
            createPortal(
              children,
              this.iframeBody
            )}
            {this.iframeHead &&
            createPortal(
              <Head />,
              this.iframeHead
            )}
        </iframe>
      )
    }
  }

export default withApollo(ContentManager);