/*
    Přidání potahu
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CAMPAIGN} from '../Queries/campaign.js';
import { withApollo } from 'react-apollo';
import CampaignLib from '../Library/campaign';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import { Editor } from '@tinymce/tinymce-react';
import CampaignEmails from './CampaignEmails';
import CampaignContacts from './CampaignContacts';

const INITIAL_STATE = {
    apolloError:"",
    selectedCampaignID:null,
    allForms:[],
    allServices:[],
    selectedTab:1,
    formData:{
        campaignID:0,
        name:"",
        active:0,
        isForMainProduct:0,
        isForNewsletter:0,
        selectedServices:[],
        selectedForms:[]
    }
}

class AddEditCampaign extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.campaign = new CampaignLib(this); 
        this.campaign.getData();
        
    }

    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }
    
    render(){

        const {selectedCampaignID,apolloError,allForms,allServices,selectedTab} = this.state;
        return(

            <Mutation 
                mutation={ADD_EDIT_CAMPAIGN}
                update = {async (cache, response) => {

                    this.campaign.updateList(cache,response,selectedCampaignID);

                    let notify = new Notification();

                    if(selectedCampaignID && selectedCampaignID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {  

                    if(selectedCampaignID && selectedCampaignID != 0){
                        this.props.openCloseModal(false);
                    }else{
                        
                        this.setState({selectedCampaignID:response.addEditCampaign.campaignID,formData:{...this.state.formData, campaignID:response.addEditCampaign.campaignID}});
                    
                    }
                }}
                
            >
            {
                (addCampaign,{data,loading,error}) => {

                    const {
                        name,active,isForMainProduct,isForNewsletter,selectedServices,selectedForms
                    } = this.state.formData;

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedCampaignID && selectedCampaignID != 0 ? "Úprava kampaně" : "Přidání kampaně")}</h4>
                                    <button type="button" className="close" onClick={() => this.props.openCloseModal(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div>
                                        {!loading ?
                                            <Fragment>
                                                {error || apolloError ?
                                                    <Error text={error || apolloError} />
                                                :
                                                    <div>

                                                        <ul className="nav nav-tabs" role="tablist">
                                                            <li className="nav-item">
                                                                <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className={(selectedTab == 2 ? "nav-link active" : (selectedCampaignID ? 'nav-link' : 'nav-link disabled'))} href="#emails" onClick={(e) => {e.preventDefault();if(selectedCampaignID)this.showTab(e,2)}}>Emaily</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className={(selectedTab == 3 ? "nav-link active" : (selectedCampaignID ? 'nav-link' : 'nav-link disabled'))} href="#emails" onClick={(e) => {e.preventDefault();if(selectedCampaignID)this.showTab(e,3)}}>Kontakty</a>
                                                            </li>

                                                        </ul>

                                                        <div className="tab-content" id="myTabContent">
                                                            {selectedTab == 1 ?
                                                                <>
                                                                    <div className="row">
                                                                                                                                
                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">Název kampaně</label>
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="text" name="name" value={name} onChange={(e) => this.campaign.formHandle(e)} />
                                                                            </div>
                                                                        </div>

                                                                    
                                                                        <div className="col-12 col-sm-3">
                                                                            <label className="input-label">Aktivní</label>
                                                                            <div className="form-group">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.campaign.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="active1">
                                                                                        Ano
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.campaign.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="active2">
                                                                                        Ne
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="card">

                                                                        <div className="card-header">
                                                                            Kdy se má kampaň spustit ?
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-12 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input className="form-check-input" type="checkbox" name="isForMainProduct" id="isForMainProduct" value="1" checked={(isForMainProduct == 1 ?  true : false)} onChange={(e) => this.campaign.formHandle(e)} />
                                                                                            <label className="form-check-label" htmlFor="isForMainProduct">
                                                                                                <strong>Při zakoupení hlavního produktu</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input className="form-check-input" type="checkbox" name="isForNewsletter" id="isForNewsletter" value="1" checked={(isForNewsletter == 1 ?  true : false)} onChange={(e) => this.campaign.formHandle(e)} />
                                                                                            <label className="form-check-label" htmlFor="isForNewsletter">
                                                                                                <strong>Při vložení emailu do newsletteru na stránce webu</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-12 col-md-6">
                                                                                    <p style={{fontSize: "0.875rem"}}><strong>Při zakoupení některého z produktu níže</strong></p>
                                                                                    
                                                                                    <div>
                                                                                        {allServices && allServices.length > 0 && allServices.map((item,index) => {

                                                                                            var checked = false;
                                                                                            for(let i in selectedServices){
                                                                                                if(selectedServices[i] == item.serviceID)checked = true;
                                                                                            }

                                                                                            return(
                                                                                                <div key={index} className="form-group">
                                                                                                    <div className="form-check form-check-inline">
                                                                                                        <input className="form-check-input" type="checkbox" name="service" id={"service" + item.serviceID} value={item.serviceID} checked={checked} onChange={(e) => this.campaign.checkServices(e,item.serviceID)} />
                                                                                                        <label className="form-check-label" htmlFor={"service" + item.serviceID}>
                                                                                                            {item.name}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )

                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-md-6">
                                                                                    <p style={{fontSize: "0.875rem"}}><strong>Při vyplnění některého z formulářu níže</strong></p>
                                                                                    <div>
                                                                                        {allForms && allForms.length > 0 && allForms.map((item,index) => {

                                                                                            var checked = false;
                                                                                            for(let i in selectedForms){
                                                                                                if(selectedForms[i] == item.formID)checked = true;
                                                                                            }

                                                                                            return(
                                                                                                <div key={index} className="form-group">
                                                                                                    <div className="form-check form-check-inline">
                                                                                                        <input className="form-check-input" type="checkbox" name="form" id={"form" + item.formID} value="1" checked={checked} onChange={(e) => this.campaign.checkForms(e,item.formID)} />
                                                                                                        <label className="form-check-label" htmlFor={"form" + item.formID}>
                                                                                                            {item.name}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )

                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </>
                                                            :null}
                                                            {selectedTab == 2 &&
                                                                <CampaignEmails campaignID = {selectedCampaignID} />
                                                            }
                                                            {selectedTab == 3 &&
                                                                <CampaignContacts campaignID = {selectedCampaignID} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </Fragment>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.campaign.addCampaign(addCampaign)}>{(selectedCampaignID && selectedCampaignID != 0 ? "Upravit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCampaign);