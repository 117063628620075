/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass:"",
    backgroundImage:{},
    fullWidth:0,
    fullHeight:0,
    paddingClass:"padding",
    showAddElements:false,
    elements:[]
}

/**
 * Seznam veškerých elementů
 */

const ELEMENTS_LIST = ["columns","headline","paragraph"];

/**
 * definice elementu sloupce
 */

const COLUMNS = {
    columns:{
        showMenu:true,
        cols:[]
    }
}

/**
 * definice elementu sloupce
 */

const COLUMN = {
    showAddElements:false,
    showMenu:true,
    elements:[]
}

/**
 * Definice elementu nadpis
 */

const HEADLINE = { 
    headline: {
        showMenu:true,
        showSettings:false,
        type:"h1",
        text:"Nadpis sekce",
        textAlign:"text-center",
        hasSubHeadline:1,
        subHeadlineText:"Podnadpis dané sekce s trošku delším textem."
    }
}

const PARAGRAPH = { 
    paragraph: {
        showMenu:true,
        showSettings:false,
        showLinkSettings:false,
        text:"Lorem ipsum dolor sit amet, <strong>conse <em>ctetuer quis</em> adip<em>isc</em>ing</strong> elit. <strong>Etiam</strong> <strong>Etiam</strong> ligula <a href=\"fdsf\">pede</a>, sagittis quis, interdum ultricies, scelerisque eu.",
        textAlign:"text-left",
    }
}


export {SECTION,COLUMNS,COLUMN,HEADLINE,ELEMENTS_LIST,PARAGRAPH};