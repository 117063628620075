import { useState } from "react";
const UseFormHandle = (formData) => {

    const [newFormData, SetNewFormdata] = useState(formData);

    const SetFormData = (e,val) => {

        var n,v;

        if(!e.target)
        {
            v = val;
            n = e;
        }
        else
        {
            const t = e.target;
            v = t.type === 'checkbox' ? t.checked : t.value;
            n = t.name;
        }
        
        SetNewFormdata({...newFormData,[n]: v});
    }

    const SetCompleteFormData = (data) => {
        SetNewFormdata((prev) => {
            return {...prev,...data};
        });
    }

    const SetFormLangData = (paramName,data) => {

        let langs = [...newFormData.langs];
        for(let i in langs){
            if(langs[i].lang == newFormData.selectedLang)
            {
                langs[i] = {...langs[i],[paramName] : data};
            }
        }

        SetNewFormdata({...newFormData,langs: langs});
    }

    const GetFormLangValue = (paramName) => {
        for(const lang of newFormData.langs){
            if(lang.lang == newFormData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    const SetArrayObjValue = (e,objParam,index,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = {...arr[index],[n]:v}
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const GetEventData = (e,val) => {

        var n,v;
    
        if(!e.target)
        {
            v = val;
            n = e;
        }
        else
        {
            const t = e.target;
            if(t.type === 'checkbox')
                v = t.checked
            else if(t.type === 'radio')
                v = parseInt(t.value);
            else
                v = t.value;
    
            n = t.name;
        }
    
        return {n,v}
    }
    
    return{
        formData:newFormData,
        SetFormData,
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        SetArrayObjValue
    }
}

export default UseFormHandle;