import { useState } from "react";
import { useQuery } from "react-apollo";
import { GET_INVOICE } from "../Queries/invoice";
import moment from 'moment';

const UseGetInvoice = (selectedInvoiceID,CallBack) => {

    const [allDomains, SetAllDomains] = useState([]);
    const [allCountries, SetAllCountries] = useState([]);

    const {loading,error} = useQuery(GET_INVOICE,{
        variables:{
            invoiceID:selectedInvoiceID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            console.log(d);

            if(selectedInvoiceID != 0)
            {
                var items = [];
                for(let val of d.Invoice.items)
                {
                    items.push({
                        text:val.text,
                        count:val.count,
                        amount:val.amount
                    })
                }
                CallBack({
                    invoiceID: selectedInvoiceID,
                    customerID: d.Invoice.customerID,
                    serviceID: d.Invoice.serviceID,
                    domainID: d.Invoice.domainID,
                    name: d.Invoice.name,
                    surname: d.Invoice.surname,
                    email: d.Invoice.email,
                    tel: d.Invoice.tel, 
                    street: d.Invoice.street,
                    city: d.Invoice.city,
                    zip: d.Invoice.zip,
                    countryID: d.Invoice.countryID,
                    company: d.Invoice.company,
                    ic: d.Invoice.ic,
                    dic: d.Invoice.dic,
                    dateAdd: moment(d.Invoice.dateAdd).toDate(),
                    dueDate: moment(d.Invoice.dueDate).toDate(),
                    items: items,
                    sendInvoiceByEmail: 1
                });
            }

            SetAllDomains(d.allDomains);
            SetAllCountries(d.allCountries);
        }
    });

    return{
        loading: loading,
        error: error,
        allDomains,
        allCountries
    }

}

export default UseGetInvoice;