/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

export const ADD_EDIT_INVOICE = gql`
    mutation AddEditInvoice($data:AddInvoiceData){
        AddEditInvoice(data:$data){
            invoiceID
            invoiceNumber
        }
    }
`;

/**
 *  Seznam veškerých filtrovaných faktur
 */
export const GET_INVOICES = gql`
    query AllFilteredInvoices($offset:Int, $limit: Int,$dateFrom:String,$dateTo:String,$selectedDomains:[ID],$selectedServices:[ID],$lang:String){
        allFilteredInvoices(offset:$offset,limit:$limit,dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices){
            invoiceID
            invoiceNumber
            name
            surname
            email
            tel 
            dateAddStr
            dueDateStr
            totalPrice
            domainID
            currency
            items{
                text
            }
            invoiceCreditNote{
                invoiceCreditNoteID
            }
        }
        allDomains{
            domainID
            domainName
        }
        allServices(lang:$lang){
            name
            serviceID
        }
        totalInvoices(dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices)
        totalInvoicesPrice(dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices)
        
    }
`;

export const GET_INVOICE = gql`
    query Invoice($invoiceID:ID){
        Invoice(invoiceID:$invoiceID){
            invoiceID
            customerID
            serviceID
            domainID
            name
            surname
            email
            tel 
            street
            city
            zip
            countryID
            invoiceNumber
            company
            ic
            dic
            dateAdd
            dueDate
            items{
                text
                amount
                count
            }
        }
        allDomains{
            domainID
            domainName
        }
        allCountries{
            countryID
            name
        }
    }
`;

export const EXPORT_INVOICE = gql`
mutation ExportInvoice($invoiceID:ID, $domainID: ID){
    exportInvoice(invoiceID:$invoiceID,domainID:$domainID)  
}
`;

export const EXPORTED_INVOICES_TO_ZIP = gql`
mutation ExportedInvoicesToZip($invoices:[ID]){
    exportedInvoicesToZip(invoices:$invoices)  
}
`;

export const ADD_INVOICE_CREDIT_NOTE = gql`
    mutation AddInvoiceCreditNote($invoiceID:ID,$creditNoteDate:String,$reason:String){
        addInvoiceCreditNote(invoiceID:$invoiceID,creditNoteDate:$creditNoteDate,reason:$reason)  
    }
`;

export const GET_INVOICE_CREDIT_NOTES = gql`
    query AllFilteredInvoiceCreditNotes($offset:Int, $limit: Int,$dateFrom:String,$dateTo:String,$selectedDomains:[ID],$selectedServices:[ID],$lang:String,$hasDeleted:Boolean){
        allFilteredInvoiceCreditNotes(offset:$offset,limit:$limit,dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices,hasDeleted:$hasDeleted){
            invoiceCreditNoteID
            invoiceID
            creditNoteNumber
            creditNoteDate
            deleted
            invoice{
                name
                surname
                email
                tel
                invoiceID
                domainID
                invoiceNumber
                totalPrice
                currency
            }
        }
        allDomains{
            domainID
            domainName
        }
        allServices(lang:$lang){
            name
            serviceID
        }
        
    }
`;

export const REMOVE_INVOICE_CREDIT_NOTE = gql`
    mutation RemoveInvoiceCreditNote($invoiceCreditNoteID:ID){
        removeInvoiceCreditNote(invoiceCreditNoteID:$invoiceCreditNoteID)  
    }
`;

export const EXPORT_INVOICE_CREDIT_NOTES = gql`
    mutation ExportInvoiceCreditNote($invoiceCreditNoteID:ID, $domainID: ID){
        exportInvoiceCreditNote(invoiceCreditNoteID:$invoiceCreditNoteID,domainID:$domainID)  
    }
`;

export const EXPORTED_INVOICE_CREDIT_NOTES_TO_ZIP = gql`
    mutation ExportedInvoiceCreditNotesToZip($invoiceCreditNotes:[ID]){
        exportedInvoiceCreditNotesToZip(invoiceCreditNotes:$invoiceCreditNotes)  
    }
`;
